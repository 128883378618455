import { useEffect, useState } from "react";

export const useFetchData = <T> (url: string) => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(url);
        const json = await response.json();
        setData(json as T);
      } catch (error) {
        setError(error as string)
      } finally {
        setLoading(false);
      }
    };

    fetchData()

  }, [url]);

  //return necessary data

  return { data, loading, error}
};