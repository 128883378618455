import './ArticlePage.css'
import React, { useRef, useEffect } from 'react';
import { useFetchData } from 'hooks/useFetchData';
import { useParams } from 'react-router-dom';
import { transform } from 'routes';

interface Item {
    title: string
    content: string
}

interface Response {
    status: string,
    feed: object,
    items: Item[]
}

const ArticlePage = () => {
    const sectionRef = useRef<HTMLElement>(null)
    const { id } = useParams();
    const { data, loading }  = useFetchData<Response>('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@kasparhaavajoe')

    useEffect(() => {
        if (sectionRef.current && !loading) {
          sectionRef.current.innerHTML = data?.items.find(item => transform(item.title) === id)?.content || '';
        }
      }, [data, loading, id]);

    return <section className='container content' ref={sectionRef} />
}

export default ArticlePage;