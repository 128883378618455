import './ProgressBar.css'
import React, { useState, useEffect } from 'react'

const ProgressBar = () => {
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scroll = document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      setScroll((scroll / height) * 100);
    })
  }, [setScroll])

  return (
    <div className="progress">
      <span style={{ width: scroll + '%' }}></span>
    </div>
  )
}

export default ProgressBar