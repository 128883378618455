import './HomePage.css'
import React from 'react';
import { useFetchData } from 'hooks/useFetchData';
import { Link } from 'react-router-dom';
import { routes, transform } from 'routes';

interface Item {
    title: string
    link: string
    content: string
}

interface Response {
    status: string,
    feed: object,
    items: Item[]
}

const HomePage = () => {
  const { data }  = useFetchData<Response>('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@kasparhaavajoe')

  return (
    <section className='container home-content'>
      <ul>
        {data?.items.map((item, idx) => <li key={idx}><Link to={`${routes.insights}/${transform(item.title)}`}>{item.title}</Link></li>)}
      </ul>
    </section>
  )
}

export default HomePage;