import './Header.css'
import React from 'react'
import ProgressBar from 'components/ProgressBar'
import logo from 'assets/logo.svg'
import { Link } from 'react-router-dom'
import { routes } from 'routes'

const Header = () => (
  <nav className='navbar'>
    <div className='container'>
      <Link to={routes.home}>
        <img className="logo" src={logo} alt="Aspensource" />
      </Link>
    </div>
    <ProgressBar />
  </nav>
)

export default Header;