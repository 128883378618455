import './App.css';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LandingPageLayout from 'layouts/LandingPageLayout';
import ArticlePage from 'pages/ArticlePage';
import HomePage from 'pages/HomePage';
import { routes } from 'routes';

const router = createBrowserRouter([
  {
    path: routes.home,
    element: <LandingPageLayout />,
    children: [
      {
        path: routes.home,
        element: <HomePage />
      },
      {
        path: `${routes.insights}/:id`,
        element: <ArticlePage />
      }
    ]
  }
])

const App = () => <RouterProvider router={router} />


export default App;
